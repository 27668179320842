@use "~Assets/colors" as c;

.container{
    display: flex;
    flex-direction: column;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}